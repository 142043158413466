<template>
    <div>
        <label v-if="label" class="form-label" v-html="label"></label>
        <p v-if="text" class="form-text" v-html="text"></p>
        <div class="row">
            <div v-for="rating in relationshipRatings" class="col">
                <input type="checkbox"
                    :id="'relationshipRaing-'+rating.score+'-'+uid"
                    :value.number="rating.score"
                    autocomplete="off"
                    :checked="selected === rating.score"
                    class="btn-check"
                    @change="$emit('update', parseInt($event.target.value))"
                >
                <label :for="'relationshipRaing-'+rating.score+'-'+uid" class="btn p-0 pt-1 d-block label-emoji" :class="{
                    'btn-success': selected === rating.score
                }">{{ rating.emoji }}</label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const { $uid } = useNuxtApp();

const props = defineProps<{
    selected: number|null;
    label?: string;
    text?: string;
}>();

const emit = defineEmits<{
    (event: 'update', value: number): void,
}>();


const { loadRelationshipRatings } = useActivityAttributes();
const relationshipRatings = await loadRelationshipRatings();
const uid = ref<string|null>(null);

onMounted(() => {
    uid.value = $uid();
});

</script>

<style>
.label-emoji {
    font-size: 1.5rem;
}
</style>
