<template>
    <div class="mb-4">
        <label class="form-label">{{ label ?? 'Which contact(s) were involved?' }}</label>
        <div class="row align-items-center g-1">
            <div class="col">
                <VueMultiselect
                    :disabled="pending"
                    :options="state.contacts"
                    label="full_name"
                    v-model="state.selectedContacts"
                    placeholder="Select a contact (type to search)"
                    :select-label="null"
                    :deselect-label="null"
                    :show-no-results="true"
                    :internal-search="true"
                    :multiple="true"
                    track-by="id"
                >
                    <template #noResult="prop">
                        No contacts found for <em>{{ prop.search }}</em>.
                    </template>
                    <template #noOptions="prop">
                        Account has no contacts.
                    </template>
                    <template #tag="prop">
                        <span class="multiselect__tag">
                            <span>{{ prop.option.full_name }}</span>
                            <i tabindex="1" class="multiselect__tag-icon" @click="removeContact(prop.option)"></i>
                        </span>
                    </template>
                    <template #option="prop">
                        {{ prop.option.full_name }}<br>
                        <small class="text-muted">{{ prop.option.title }}</small>
                    </template>
                </VueMultiselect>
            </div>
            <div class="col-auto">
                <button class="btn btn-link" @click.prevent="loadContacts()"
                    ref="refreshBtn"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Refresh contacts list"
                >
                    <Icon icon="sync" :class="{ 'spinner': pending }" />
                </button>
            </div>
        </div>
        <p class="small pe-auto" style="color:#3d96ca" @click="showContactForm=true"><span role="button">Add new contact</span></p>

        <template v-if="showContactForm">
            <ContactForm
                :creating-new-contact="true"
                :account-id="accountId"
                :show-cancel-button="true"
                @cancel="showContactForm=false"
                @submit="addContact"
            />
        </template>

    </div>
</template>

<script setup lang="ts">
import { Tooltip } from "bootstrap";

const props = defineProps<{
    accountId: string;
    label?: string,
    selected?: string[];
}>();

interface IState {
    contacts: IContact[];
    selectedContacts: IContact[];
}

const state = reactive<IState>({
    contacts: [],
    selectedContacts: [],
});

const emit = defineEmits<{
    (event: 'update', value: IContact[]): void,
}>();

const refreshBtn = ref(null);
let refreshBtnTooltip: Tooltip | undefined;

const showContactForm = ref<boolean>(false);
const addContactText = ref<string>("Add new contact")


const pending = ref(false);
const loadContacts = async (): void => {
    pending.value = true;
    const { data, error } = await useApiFetch('/api/accounts/'+props.accountId+'/contacts');
    state.contacts = data.value.contacts;
    pending.value = false;
}

if (props.selected) {
    state.selectedContacts = state.contacts.filter((contact: IContact) => {
        return props.selected.indexOf(contact.id) !== -1;
    });
}

watch(() => props.selected, (contacts: Array<IContact>) => {
    state.selectedContacts = state.contacts.filter((contact: IContact) => {
        return props.selected.indexOf(contact.id) !== -1;
    });
});

watch(() => props.accountId, async (accountId: string) => {
    state.contacts = [];
    state.selectedContacts = [];
    loadContacts();
});

watch(() => state.selectedContacts, (contacts: Array<IContact>) => {
    emit('update', contacts)
}, { deep: true });

const addContact = (contact: IContact) => {
    state.contacts.push(contact);
    state.selectedContacts.push(contact)
    showContactForm.value = false;
}

const removeContact = (contact: IContact) => {
    state.selectedContacts.splice(state.selectedContacts.indexOf(contact), 1);
}

onMounted(() => {
    refreshBtnTooltip = new Tooltip(refreshBtn.value!);
    loadContacts();
});

</script>

<style lang="scss" scoped>
.spinner {
    animation: spin 1.5s infinite;
    display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
